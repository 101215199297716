import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import GlobalQuickLinks from "@components/globalQuickLinks"

import {
  LearnMoreButton,
  QuickLinkButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const TikTokPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Instagram Follower Links | Jimmy Ether"
        ogDescription="Quick links to things we've recently discussed on Instagram."
        image="https://thesoogs.com/images/the-soogs-indie-pop-atlanta.jpg"
        url="https://thesoogs.com"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Quick Links</h1>
            <p>
              Thanks for visiting from Instagram. Here are some links to things we've been posting about.
            </p>
            <GlobalQuickLinks />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Contact The Soogs</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}
export default TikTokPage
